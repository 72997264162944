import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Circle, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import clock from '../../Assets/images/clock-1.jpeg';
import axios from 'axios';
import CheckStatus from '../API/CheckStatus';
import { Spin } from 'antd';
import { handleClockIn } from '../API/clockInHandler';
import ClockInOutRecord from '../API/ClockInOutRecord';
import moment from 'moment-timezone'; 

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const ClockIn = () => {
  const [position, setPosition] = useState(null);
  const [isWithinZone, setIsWithinZone] = useState(false);
  const [showMap, setShowMap] = useState(false); // Default to false to hide map initially
  const [status, setStatus] = useState(null);
  const [record, setRecord] = useState(null);
  const [forceLocation, setForceLocation] = useState(null);
  const [geoError, setGeoError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const telegram = window.Telegram.WebApp;
  const tg_chat_id = telegram.initDataUnsafe?.user?.id; //5591115278 раскомментируйте



  const locations = [
    { id: 1, center: [25.18627, 55.2747997], radius: 100 }, // Westburry Office
    { id: 2, center: [25.0978092, 55.15629569999999], radius: 50 }, // Concord Tower
    { id: 3, center: [25.031906, 55.185639], radius: 100 }, // AxDesign Warehouse
    { id: 4, center: [25.1854391, 55.261788], radius: 100 }, // Biltmore (Exclusive Dep)
    { id: 5, center: [25.1088434, 55.1802915], radius: 100 }, // Biltmore Sufouh (Exclusive dep)
    { id: 6, center: [25.2763731, 55.28667970000001], radius: 200 }, // Cruise Terminal Stand
    { id: 7, center: [25.2519659, 55.33280269999999], radius: 200 }, // Deira City Centre
    { id: 8, center: [25.075572, 55.130413], radius: 100 }, // JBR Stand
    { id: 9, center: [25.1181203, 55.2005828], radius: 300 }, // MoE
    { id: 10, center: [25.1851115, 55.2854719], radius: 100 }, // The Terraces (Exclusive Dep)
    { id: 11, center: [24.4888196, 54.6086898], radius: 300 }, // Yas Mall Stand
    // { id: 12, center: [25.1794939, 55.3056475], radius: 100 }, // Test location
  ];
  
  useEffect(() => {
    const storedForceLocation = localStorage.getItem('forceLocation');
    setForceLocation(Number(storedForceLocation))

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const { latitude, longitude } = pos.coords;
        setPosition([latitude, longitude]);
        checkPosition([latitude, longitude]);
      },
      (error) => {
        setGeoError(true)
      });
    }

    const checkStatus = async () => {
      const statusResult = await CheckStatus(tg_chat_id);
      setStatus(statusResult);
    };
    checkStatus();

    const checkLastClockInOutRecord = async () => {
      const lastClockInOutRecord = await ClockInOutRecord(tg_chat_id)
      setRecord(lastClockInOutRecord)
    }
    checkLastClockInOutRecord();

  }, []);

  const checkPosition = (pos) => {
    const withinAnyZone = locations.some(location => {
      const distance = L.latLng(pos).distanceTo(L.latLng(location.center));
      return distance <= location.radius;
    });
    setIsWithinZone(withinAnyZone);
  };

  const formatDubaiTime = (utcDateTime) => {
    return moment(utcDateTime).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss');
  };

  // const handleClockInClick = async () => {
  //   const newRecord = await handleClockIn({ isWithinZone, status, setStatus, position, tg_chat_id, forceLocation });
  //   setRecord(newRecord); // Обновляем состояние с новым record
  // };


  const handleClockInClick = async () => {
    setIsLoading(true); // Устанавливаем лоадер перед началом запроса
    try {
      const newRecord = await handleClockIn({ isWithinZone, status, setStatus, position, tg_chat_id, forceLocation });
      setRecord(newRecord);
    } finally {
      setIsLoading(false); // Снимаем лоадер после завершения запроса
    }
  };

  
  
  return (
    <div className='clock-in-page'>
      <div className='info-clockin'>
        <ul>
          <li>
            <i className="fa-regular fa-clock"></i>
            {record ? (
              record.attributes.type === 'clock-in' ? (
                <p>Clock in at {formatDubaiTime(record.attributes.timestamp)}</p>
              ) : (
                <p>Clock out at {formatDubaiTime(record.attributes.timestamp)}</p>
              )
            ) : (
              <p>N/A</p>
            )}
            <div className="indicator">
              <i className={`fa-solid fa-building-circle-check ${status ? 'green' : 'red'}`}></i>
            </div>
          </li>
        </ul>
      </div>
      {!geoError && (
        <div className="image-container">
          <img className="watch" src={clock} alt="Clock" />
        </div>
      )}
      {geoError && (
        <div className="map-clockin">
          <div className="info-block">
            <h5>Please enable location on your phone</h5>
            <h6><i className="fa-brands fa-android"></i> Android:</h6>
            <p>Settings > Location > On </p>
            <hr />
            <h6><i className="fa-brands fa-apple"></i> IOS:</h6>
            <p>Settings > Privacy & Security > Location Services > On</p>
            <hr />
            <h6><i className="fa-regular fa-note-sticky"></i> Notes:</h6>
            <p>If you encounter any problems please contact the IT department</p>
          </div>
        </div>
      )}
      {showMap && (
        position ? (
          <MapContainer center={position} zoom={13} className="map-clockin">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
              <Popup>Your location</Popup>
            </Marker>
            {locations.map(location => (
              <Circle
                key={location.id}
                center={location.center}
                radius={location.radius}
                color={isWithinZone ? 'green' : 'red'}
              >
                <Popup>Clock-in zone</Popup>
              </Circle>
            ))}
          </MapContainer>
        ) : (
          <div className="spin-style">
            <Spin size="large" tip="Loading...">
              <div style={{ height: '100vh', width: '100vw' }} />
            </Spin>
          </div>
        )
      )}
      
      {!geoError && (
        <button
          onClick={handleClockInClick}
          className={`clock-in-button ${status ? 'red-button' : ''} ${isLoading ? 'actionIsLoading': ''}`}
          disabled={!position || isLoading} // Отключаем кнопку, если позиция не загружена или идет загрузка
        >
          {isLoading ? (
            <>
             <Spin size="small" /> {/* Отображаем Spin компонент, если идет загрузка */}
            <span>Loading...</span>
            </>
          ) : (
            <>
              <i className={`fa-solid ${status ? 'fa-stop' : 'fa-play'}`}></i>
              <span>{status ? 'Clock Out' : 'Clock In'}</span>
            </>
          )}
        </button>
      )}
      
    </div>
  );
};

export default ClockIn;
